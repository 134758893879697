<template>
  <div class="system-setup">
    <el-tabs ref="tabs" v-model="activeName" @tab-click="handleClick">
      <!-- 组织机构 -->
      <el-tab-pane v-if="isAdmin || isTissueAdmin || isProjectAdmin" label="组织机构" name="first">
        <institutional-framework v-if="activeName == 'first'"></institutional-framework>
      </el-tab-pane>
      <!-- 权限设置 -->
      <el-tab-pane v-if="isAdmin" label="角色列表" name="second">
        <permission-setting v-if="activeName == 'second'"></permission-setting>
      </el-tab-pane>
      <!-- 用户管理 -->
      <el-tab-pane v-if="isAdmin || isTissueAdmin || isProjectAdmin" label="用户管理" name="third">
        <user-management v-if="activeName == 'third'"></user-management>
      </el-tab-pane>
      <!-- 隐患类别库 -->
      <el-tab-pane  label="隐患类别" name="fourth">
        <hidden-danger-category v-if="activeName == 'fourth'"></hidden-danger-category>
      </el-tab-pane>
      <!-- 隐患库 -->
      <el-tab-pane  label="隐患库" name="fifth">
        <hidden-danger-bank v-if="activeName == 'fifth'"></hidden-danger-bank>
      </el-tab-pane>
      <!-- 检查类型 -->
      <el-tab-pane label="检查类型" name="sixth">
        <check-type v-if="activeName == 'sixth'"></check-type>
      </el-tab-pane>
      <!-- 分包单位库-项目层专属 -->
      <el-tab-pane v-if="isProject" label="分包单位" name="eighth">
        <subpackage v-if="activeName == 'eighth'"></subpackage>
      </el-tab-pane>
      <!-- 责任区域库-项目层专属 -->
      <el-tab-pane v-if="isProject" label="责任区域" name="seventh">
        <duty-area v-if="activeName == 'seventh'"></duty-area>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { tabIndex } from '@/utils/local-cache'
export default {
  name: "system-setup",

  components: {
    InstitutionalFramework: () => import('./components/institutional-framework'),
    PermissionSetting: () => import('./components/permission-setting'),
    UserManagement: () => import('./components/user-management'),
    HiddenDangerCategory: () => import('./components/hidden-danger-category'),
    HiddenDangerBank: () => import('./components/hidden-danger-bank'),
    CheckType: () => import('./components/check-type'),
    DutyArea: () => import('./components/duty-area'),
    Subpackage: () => import('./components/subpackage')
  },

  props: {},
  data() {
    return {
      activeName: 'first',
      defaultProps: {
        children: "children",
        label: "label",
      },
      expandDefault: [],
      checkDefault: []
    };
  },

  computed: {
    ...mapGetters(["optionItem"]),
    isAdmin() {
      return this.optionItem && this.optionItem.roleNameList && this.optionItem.roleNameList.includes("超级管理员")
    },
    isTissueAdmin() {
      return this.optionItem && this.optionItem.roleNameList && this.optionItem.roleNameList.includes("组织管理员");
    },
    isProjectAdmin() {
      return this.optionItem && this.optionItem.roleNameList && this.optionItem.roleNameList.includes("项目管理员");
    },
    isProject(){
      return this.optionItem && this.optionItem.departmentType == 'project';
    },
    isCompany(){
      return this.optionItem && this.optionItem.departmentType == 'company';
    }
  },

  watch: {
    isCompany(nv){
      if (nv) return
      if (this.activeName == 'ninth' || this.activeName == 'tenth' || this.activeName == 'eleventh' || this.activeName == 'twelfth') {
        this.activeName = 'first'
      }
    },
    checkDefault(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          document.querySelector(".el-tree-node__content").click();
        });
      }
    },
  },
  mounted() {},
  created() {
    if (tabIndex.get()) this.activeName = tabIndex.get()
    else this.activeName = 'first'
  },
  beforeDestroy() {
    this.activeName = ''
    tabIndex.set('')
  },
  methods: {
    // 切换tabs
    handleClick() {
      tabIndex.set(this.activeName)
    },
  },
};
</script>

<style lang="stylus">
.system-setup {
  .content {
    height: 100%;

    .nameTit {
      line-height: 40px;
      padding-left: 10px;
      padding-right: 10px;
      background: #f5f5f5;
      text-align: left;
      font-size: 16px;
      border-radius: 6px;
    }

    .tissue-pro {
      width: 30%;
      height: 100%;
      padding: 10px;
      border-right: 1px solid #666666;

      .cont {
        padding: 10px 0;

        .custom-tree-node {
          font-size: 15px !important;
        }

        .el-tree-node__expand-icon {
          font-size: 19px !important;
        }

        .custom-tree-node {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          padding-right: 8px;
        }
      }
    }

    .tissue-user {
      width: 70%;
      height: 100%;
      padding: 10px;

      .cont {
        padding: 10px 0;
      }
    }
  }
}

.content {
  .el-table .cell {
    text-align: center;
  }

  .has-gutter {
    .el-checkbox {
      display: none;
    }
  }
}

.el-dialog {
  .el-dialog__header {
    background: #3396fb;
    display: flex;
    padding: 15px 20px;

    .el-dialog__title, .el-dialog__headerbtn .el-dialog__close {
      color: #ffffff;
    }
  }

  .has-gutter {
    .el-checkbox {
      display: none;
    }
  }
}

.choose-user {
  .el-dialog__body {
    padding: 30px 20px 0;

    .el-form-item {
      margin-bottom: 10px;
    }

    .el-form-item__label {
      text-align: left;
    }

    .el-form-item__content {
      text-align: left;
    }

    .el-select {
      width: 50%;
    }
  }
}

.el-tree-node__content {
  height: 35px;
  line-height: 35px;
}

.is-current {
  background: #f2f2f2;
}

.el-tree-node:focus {
  > .el-tree-node__content, .el-tree-node__expand-icon {
    color: #fff;
    background: #A9A9A9;
  }
}
</style>
